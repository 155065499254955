/**
 * Don't do any changes to this file
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
/// <reference types="react-scripts" />

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'tia-button': unknown;
      'tia-modal': unknown;
      'tia-select': unknown;
      'tia-input': unknown;
      'tia-icon': unknown;
    }
  }
}

if (process.env.NODE_ENV === 'development') {
  const getWidgetName = (name: string): string => name.replace('/widgets/', '').replace('/index.tsx', '');
  const routes = (process.env.ROUTES as unknown) as string[];

  const IntroductionText = (): JSX.Element => {
    if (!routes) {
      return (
        <pre style={{ fontFamily: 'sans-serif' }}>
          Start with running <code style={{ fontFamily: 'sans-serif', fontWeight: 900 }}>create-tia-widget</code> in
          terminal to create your first widget &#128522;
        </pre>
      );
    }

    return (
      <pre style={{ fontFamily: 'sans-serif' }}>
        To create more widgets run <code style={{ fontFamily: 'sans-serif', fontWeight: 900 }}>create-tia-widget</code>{' '}
        in terminal &#128522;
      </pre>
    );
  };

  const IntroductionPage = (): JSX.Element => (
    <>
      <h1 style={{ fontFamily: 'sans-serif' }}>Welcome to introduction page</h1>
      <IntroductionText />
    </>
  );

  const App = (): JSX.Element => (
    <Router>
      <div style={{ height: '100%' }}>
        <ul>
          <li>
            <Link to="/">Introduction</Link>
          </li>
          {routes.map((route) => (
            <li key={route}>
              <Link to={route}>{getWidgetName(route)}</Link>
            </li>
          ))}
        </ul>

        <hr />
        <Switch>
          <Route exact path="/">
            <IntroductionPage />
          </Route>
          {routes.map((route) => (
            <Route key={route} path={route}>
              <div
                style={{ height: '100%', padding: '1rem' }}
                dangerouslySetInnerHTML={{ __html: `<${getWidgetName(route)} />` }}
              />
            </Route>
          ))}
        </Switch>
      </div>
    </Router>
  );

  ReactDOM.render(<App />, document.getElementById('root'));
}
