import { lazy } from 'react';
import { registerCustomElement } from '@tia/create-tia-widget-library';
import { IDataAttributes } from './TiaPolicyQuotesWidgetWebComponent';
import FallbackComponent from '../../shared/FallbackComponent';

const TiaPolicyQuotesWidget = lazy(() => import('./TiaPolicyQuotesWidgetWebComponent'));

registerCustomElement<IDataAttributes>(
  TiaPolicyQuotesWidget,
  FallbackComponent,
  'tia-policy-quotes-widget',
  ['partyId']
);
