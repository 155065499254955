import { lazy } from 'react';
import { registerCustomElement } from '@tia/create-tia-widget-library';
import { IDataAttributes } from './TiaClaimListWidgetWebComponent';
import FallbackComponent from '../../shared/FallbackComponent';

const TiaClaimListWebComponent = lazy(() => import('./TiaClaimListWidgetWebComponent'));

registerCustomElement<IDataAttributes>(
  TiaClaimListWebComponent,
  FallbackComponent,
  'tia-claim-list-widget',
  ['userId']
);
