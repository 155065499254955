import React from 'react';
import { Spinner } from '@tia/react-ui-library';
import styled from 'styled-components';

const FallbackComponent: React.FC = () => {
  return (
    <FallbackContainer>
      <Spinner size={50} />
    </FallbackContainer>
  );
};

const FallbackContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default FallbackComponent;
