import { lazy } from 'react';
import { registerCustomElement } from '@tia/create-tia-widget-library';
import { IDataAttributes } from './TiaClaimNextQuestionWidget';
import '@tia/ui-components';
import FallbackComponent from '../../shared/FallbackComponent';

if (process.env.NODE_ENV === 'development') {
  require('../../auth0/auth0');
}

const TiaClaimNextQuestion = lazy(() => import('./TiaClaimNextQuestionWidget'));

registerCustomElement<IDataAttributes>(
  TiaClaimNextQuestion,
  FallbackComponent,
  'tia-claim-next-question-widget',
  ['claim-id', 'stage-id', 'user-id', 'mobile', 'language']
);
